<template>
    <div id="game-left-area">
      <TabDefault style="padding-right:20px;"/>

      <div v-if="datas.length==0">
        <b-row class="league-area py-5 m-0">
          <b-col class="col-12 ta-center">등록된 경기가 존재하지 않습니다</b-col>
        </b-row>
      </div>

      <div :key="i" v-for="(item, i) in datas" class="">
        <b-row class="league-area p-0 m-0" v-if="i == 0 || (i > 0 && item.league != datas[i-1].league)">
          <b-col class="d-flex left b-first p-0 m-0 col-12 col-sm-12 league-name">
            <b-img class="country-img ml-05" :src="makeLeagueImg(item)"></b-img>
            <span class="ml-h">{{ (item.league_code)?item.league_code:item.league }}</span>
            <span v-if="item.sports_id == 1" class="sub-name ml-05"> [ 전반전 ]</span>
            <span v-else-if="item.sports_id == 16" class="sub-name ml-05"> [ 5이닝 ]</span>
            <span v-else-if="item.sports_id == 17" class="sub-name ml-05"> [ 1P ]</span>
            <span v-else-if="item.sports_id == 18" class="sub-name ml-05"> [ 1쿼터 ]</span>
            <span v-else-if="item.sports_id == 91" class="sub-name ml-05"> [ 1세트 ]</span>
          </b-col>
        </b-row>

        <b-row class="game-list-data p-0 m-0" id="game_list" v-if="item.smp.length > 0">

          <b-col class="d-none d-md-flex col-md-1 center b-first m-0 p-0">
            <div class="m-0 p-0 ta-center" style="color:#ffe588;">
              <span>{{ getYear(item.time) }}</span>-<span>{{ getDay(item.time) }}</span> <span>{{ getTime(item.time) }}</span>
            </div>
          </b-col>

          <b-col class="d-flex d-md-none center b-first m-0 p-0" v-if="i == 0 || (i > 0 && item.league != datas[i-1].league) || (i > 0 && item.time != datas[i-1].time)">
            <div class="m-0 p-0 ta-center" style="color:#ffe588;">
              <span>{{ getYear(item.time) }}</span>-<span>{{ getDay(item.time) }}</span> <span>{{ getTime(item.time) }}</span>
            </div>
          </b-col>

          <b-col class="col-12 col-md-11 m-0 p-0" style="width:100%;">
            <b-row class="m-0 p-0" style="width:100%;" v-if="item.sports_id == 1" title="축구">
              <b-col v-if="item.odds_type == 'Half Winners'" cols="5" class="ta-right p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">
                <span style="float:left;" class="team-name">{{ item.home_ko }}</span><span class="odds-value">{{ item.smp[0].odds }}</span>
              </b-col>
              <b-col v-if="item.odds_type == '1st Half Asian Handicap' || item.odds_type == '1st Half Over/Under'" cols="5" class="ta-right p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">
                <span style="float:left;" class="team-name">{{ item.home_ko }}</span>
                <span v-if="item.odds_type == '1st Half Asian Handicap'" style="color:yellow;" class="mr-05">H</span>
                <span v-else-if="item.odds_type == '1st Half Over/Under'" style="color:red;" class="mr-05">▲</span>
                <span class="odds-value">{{ item.smp[0].odds }}</span>
              </b-col>

              <b-col v-if="item.odds_type == 'Half Winners'" cols="2" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)"><span class="odds-value">{{ item.smp[1].odds }}</span></b-col>
              <b-col v-if="item.odds_type == '1st Half Asian Handicap' || item.odds_type == '1st Half Over/Under'" cols="2" class="ta-center p-0 odds-rate btn_rate">
                <span v-if="item.odds_type == '1st Half Asian Handicap'">{{ item.smp[0].handicap }}</span>
                <span v-else>{{ item.smp[0].name }}</span>
              </b-col>

              <b-col v-if="item.odds_type == 'Half Winners'" cols="5" class="ta-left p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[2].id" v-on:click="goCart(item, 2, $event)">
                <span class="odds-value">{{ item.smp[2].odds }}</span><span style="float:right;" class="team-name"> {{ item.away_ko }}</span>
              </b-col>
              <b-col v-if="item.odds_type == '1st Half Asian Handicap' || item.odds_type == '1st Half Over/Under'" cols="5" class="ta-left p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">
                <span class="odds-value">{{ item.smp[1].odds }}</span>
                <span v-if="item.odds_type == '1st Half Asian Handicap'" style="color:yellow;" class="ml-05">H</span>
                <span v-else-if="item.odds_type == '1st Half Over/Under'" style="color:blue;" class="ml-05">▼</span>
                <span style="float:right;" class="team-name">{{ item.away_ko }}</span>
              </b-col>
            </b-row>


            <!--야구 시작-->
            <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 16 " title="야구">
              <b-col v-if="item.odds_type == '5 Innings Handicap'" cols="5" class="ta-right p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">
                <span style="float:left;" class="team-name">{{ item.away_ko }}</span><span style="color:yellow;" class="mr-05">H</span><span class="odds-value">{{ item.smp[1].odds }}</span>
              </b-col>
              <b-col v-else-if="item.odds_type == '5 Innings Over/Under'" cols="5" class="ta-right p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[3].id" v-on:click="goCart(item, 3, $event)">
                <span style="float:left;" class="team-name">{{ item.away_ko }}</span><span  style="color:red;" class="mr-05">▲</span><span class="odds-value">{{ item.smp[3].odds }}</span>
              </b-col>

              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">
                <span v-if="item.odds_type == '5 Innings Handicap'">{{ item.smp[1].handicap }}</span>
                <span v-else>{{ item.smp[3].name }}</span>
              </b-col>

              <b-col v-if="item.odds_type == '5 Innings Handicap'" cols="5" class="ta-left p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">
                <span class="odds-value">{{ item.smp[0].odds }}</span><span style="color:yellow;" class="ml-05">H</span><span style="float:right;" class="team-name">{{ item.home_ko }}</span>
              </b-col>
              <b-col v-else-if="item.odds_type == '5 Innings Over/Under'" cols="5" class="ta-left p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[7].id" v-on:click="goCart(item, 7, $event)">
                <span class="odds-value">{{ item.smp[7].odds }}</span><span  style="color:blue;" class="ml-05">▼</span><span style="float:right;" class="team-name">{{ item.home_ko }}</span>
              </b-col>
              <!--<b-col cols="1" class="ta-center p-0 bg2 btn-more"></b-col>-->
            </b-row>

            <!--농구 시작-->
            <b-row class="m-0 p-0" style="width:100%;" v-if="item.sports_id == 18" title="농구">
              <b-col v-if="item.odds_type == '1st Quarter Handicap'" cols="5" class="ta-right p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">
                <span style="float:left;" class="team-name">{{ item.away_ko }}</span><span style="color:yellow;" class="mr-05">H</span><span class="odds-value">{{ item.smp[1].odds }}</span>
              </b-col>
              <b-col v-else-if="item.odds_type == '1st Quarter Over/Under'" cols="5" class="ta-right p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">
                <span style="float:left;" class="team-name">{{ item.away_ko }}</span><span  style="color:red;" class="mr-05">▲</span><span class="odds-value">{{ item.smp[0].odds }}</span>
              </b-col>

              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">
                <span v-if="item.odds_type == '1st Quarter Handicap'">{{ item.smp[1].handicap }}</span>
                <span v-else>{{ item.smp[1].handicap.replace('U ', '').replace('O ','') }}</span>
              </b-col>

              <b-col v-if="item.odds_type == '1st Quarter Handicap'" cols="5" class="ta-left p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">
                <span class="odds-value">{{ item.smp[0].odds }}</span><span style="color:yellow;" class="ml-05">H</span><span style="float:right;" class="team-name">{{ item.home_ko }}</span>
              </b-col>
              <b-col v-else-if="item.odds_type == '1st Quarter Over/Under'" cols="5" class="ta-left p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">
                <span class="odds-value">{{ item.smp[1].odds }}</span><span  style="color:blue;" class="ml-05">▼</span><span style="float:right;" class="team-name">{{ item.home_ko }}</span>
              </b-col>
              <!--<b-col cols="1" class="ta-center p-0 bg2 btn-more"></b-col>-->
            </b-row>
            
            <!--배구 시작-->
            <b-row class="m-0 p-0" style="width:100%;" v-if="item.sports_id == 91" title="배구">
              <b-col v-if="item.odds_type == '1st Set WinLose'" cols="5" class="ta-right p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">
                <span style="float:left;" class="team-name">{{ item.home_ko }}</span><span style="color:yellow;" class="mr-05">H</span><span class="odds-value">{{ item.smp[0].odds }}</span>
              </b-col>
              <b-col v-else-if="item.odds_type == '1st Set Over/Under'" cols="5" class="ta-right p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">
                <span style="float:left;" class="team-name">{{ item.home_ko }}</span><span  style="color:red;" class="mr-05">▲</span><span class="odds-value">{{ item.smp[0].odds }}</span>
              </b-col>

              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">
                <span v-if="item.odds_type == '1st Set WinLose'">VS</span>
                <span v-else>{{ item.smp[0].handicap.replace('U ', '').replace('O ','') }}</span>
              </b-col>

              <b-col v-if="item.odds_type == '1st Set WinLose'" cols="5" class="ta-left p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">
                <span class="odds-value">{{ item.smp[1].odds }}</span><span style="color:yellow;" class="ml-05">H</span><span style="float:right;" class="team-name">{{ item.away_ko }}</span>
              </b-col>
              <b-col v-else-if="item.odds_type == '1st Set Over/Under'" cols="5" class="ta-left p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">
                <span class="odds-value">{{ item.smp[1].odds }}</span><span  style="color:blue;" class="ml-05">▼</span><span style="float:right;" class="team-name">{{ item.away_ko }}</span>
              </b-col>
              <!--<b-col cols="1" class="ta-center p-0 bg2 btn-more"></b-col>-->
            </b-row>

            <!--아이스하키 시작-->
            <b-row class="m-0 p-0" style="width:100%;" v-if="item.sports_id == 17" title="아이스하키">
              <b-col v-if="item.odds_type == '1st Handicap'" cols="5" class="ta-right p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">
                <span style="float:left;" class="team-name">{{ item.away_ko }}</span><span style="color:yellow;" class="mr-05">H</span><span class="odds-value">{{ item.smp[1].odds }}</span>
              </b-col>
              <b-col v-else-if="item.odds_type == '1st Over/Under'" cols="5" class="ta-right p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">
                <span style="float:left;" class="team-name">{{ item.away_ko }}</span><span  style="color:red;" class="mr-05">▲</span><span class="odds-value">{{ item.smp[0].odds }}</span>
              </b-col>

              <b-col cols="2" class="ta-center p-0 odds-rate btn_rate">
                <span v-if="item.odds_type == '1st Handicap'">{{ item.smp[1].handicap }}</span>
                <span v-else>{{ item.smp[1].handicap.replace('U ', '').replace('O ','') }}</span>
              </b-col>

              <b-col v-if="item.odds_type == '1st Handicap'" cols="5" class="ta-left p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">
                <span class="odds-value">{{ item.smp[0].odds }}</span><span style="color:yellow;" class="ml-05">H</span><span style="float:right;" class="team-name">{{ item.home_ko }}</span>
              </b-col>
              <b-col v-else-if="item.odds_type == '1st Over/Under'" cols="5" class="ta-left p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">
                <span class="odds-value">{{ item.smp[1].odds }}</span><span  style="color:blue;" class="ml-05">▼</span><span style="float:right;" class="team-name">{{ item.home_ko }}</span>
              </b-col>
              <!--<b-col cols="1" class="ta-center p-0 bg2 btn-more"></b-col>-->
            </b-row>

          </b-col>
        </b-row>
      </div>
    </div>

</template>

<script>

import { BRow, BCol, BImg, BContainer, BButton, BPagination } from 'bootstrap-vue'
import EventBus from '../../event-bus';
import TabDefault from './TabDefault3.vue'
import { getYear, getDay, getTime, makeLeagueImg, makeTeamImg } from '@core/utils/utils'

export default {
  data () {
    return {
      datas: [],
      odds_name: 'Full Time Result',
      currentPage: 1,
      perPage: 50,
      rows: 0,
      sports_id: 0
    }
  },
  components: {
    BRow, BCol, BImg, BContainer,BButton,TabDefault,BPagination
  },
  created () {
    EventBus.$on('add_bet', (id)=>
    {
      var bet_id = document.querySelector("#game-left-area .bet-id-"+id);
      if( bet_id != null)
      {
          bet_id.classList.add("selected");
      }
    });

    EventBus.$on('remove_bet', (id)=>
    {
      var bet_id = document.querySelector("#game-left-area .bet-id-"+id);
      if( bet_id != null)
      {
          bet_id.classList.remove("selected");
      }
    });

    EventBus.$on('next_page', ()=>
    {
      this.getDataAdd();
    });

  },
  mounted(){
    if( localStorage.getItem("userData") != null){
      this.users = JSON.parse(localStorage.getItem("userData"));
    }

    this.getData();
  },
  updated() {
    this.getBettingDataFromCookie();

    this.$nextTick(function () {
      // 모든 화면이 렌더링된 후 실행합니다.
    });
  },
  methods: {
    async getData (page=1, sports_id=this.sports_id ) {
      this.sports_id = sports_id;
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'block';
      }
      var results = await this.$api('http://server.honey-game.com/games/special/'+page+'/'+sports_id, 'get');

      this.datas = results.datas;
      this.rows = results.total_row;

      // 배당 갱신
      var results2 = await this.$api('http://server.honey-game.com/renew/renew_list/'+this.users.agent_idx, 'get');

      for( var i=0; i<results2.datas.length; i++ ){
        var bet_id = document.querySelector("#game-left-area .bet-id-"+results2.datas[i].rate_id + " .odds-value");
        if( bet_id ){
          bet_id.innerHTML = (Math.round(results2.datas[i].rate_value * 100) / 100).toFixed(2);
        } 
      }
      
      appLoading.style.display = 'none';
    },
    async getDataAdd () {

      if( (this.rows / 100) <= this.currentPage ) return;

      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'block';
      }
      var results = await this.$api('http://server.honey-game.com/games/special/'+(++this.currentPage)+'/'+this.sports_id, 'get');
      this.datas = this.datas.concat(results.datas);

      // 배당 갱신
      var results2 = await this.$api('http://server.honey-game.com/renew/renew_list/'+this.users.agent_idx, 'get');

      for(var i=(this.currentPage-1)*100; i<this.datas.length; i++)
      {
        this.datas[i].smp = JSON.parse(this.datas[i].smp);
        this.datas[i].mode = false;
        this.datas[i].sub = [];
      }

      for( var i=0; i<results2.datas.length; i++ ){
        var bet_id = document.querySelector("#game-left-area .bet-id-"+results2.datas[i].rate_id + " .odds-value");
        if( bet_id ){
          bet_id.innerHTML = (Math.round(results2.datas[i].rate_value * 100) / 100).toFixed(2);
        } 
      }

      appLoading.style.display = 'none';
    },

    getBettingDataFromCookie(){
      if( localStorage.getItem("betData") != null){
        var betData = JSON.parse(localStorage.getItem("betData"));
        for(var value of betData)
        {
          var bet_id = document.querySelector("#game-left-area .bet-id-"+value.selected.id);
          if( bet_id != null)
          {
            if( !bet_id.classList.contains('selected') ){
              bet_id.classList.add("selected");
            }
          }
        }
      }
    },
    goCart(obj, j, event){
      var selected = obj.smp[j];

      var spans = event.target.querySelector(".odds-value");
      if( !spans ){
        spans = event.target.offsetParent.querySelector(".odds-value");
      }

      selected.odds = spans.innerHTML;//event.target.querySelector(".odds-value").innerHTML;

      //var selected;
      //selected = obj.smp[j];
      var bets = {
        'selected' : selected,
        'game_id' : obj.id,
        'league_cc' : obj.league_cc,
        'league' : obj.league,
        'league_code' :obj.league_code,
        'home' : obj.home_ko,
        'away' : obj.away_ko,
        'odds_name' : obj.odds_name,
        'odds_type' : obj.odds_type,
        'game_time' : obj.time,
        'sports_id' : obj.sports_id,
      }

      EventBus.$emit('update_cart', bets);
    },

    pageClick: function (button, page){
      this.getData(page);
      EventBus.$emit('upcoming-scroll-top');
		},

    getYear,
    getDay,
    getTime,
    makeLeagueImg,
    makeTeamImg,
  },
  beforeDestroy(){
    EventBus.$off();
  },
}
</script>

<style>
#game-left-area {width:calc(100%-10px);background-color:#242a3a !important;padding:10px;}
#game-left-area .league-area { background-color:#233054;margin:0px;color:white;font-family: "Noto Sans KR",sans-serif;border: 1px solid #111;}
#game-left-area .league-area>div {line-height:28px;align-items:center;}
#game-left-area .coulum { display: flex; flex-direction: column; flex-shrink: 1; flex-basis: auto; flex-wrap: nowrap; justify-content: center;align-items: baseline !important; }
#game-left-area .coulum .team-name {margin-left:7px;}
#game-left-area .odds-rate {line-height:36px;background-color:#13161e;margin:0px;border:1px solid #383636;cursor:pointer;color:white; padding:0px 8px !important;}
#game-left-area .btn-more {line-height:36px;background-color:#13161e;margin:0px;border:1px solid #383636;cursor:pointer;color:white; padding:0px 8px !important;}

#game-left-area .odds-rate.bg2 {background-color:#081332;}
@media (hover: hover){
  #game-left-area .odds-rate:hover { box-shadow: inset 0 0 0 1px #ffe588;transition: all .3s ease;}
}
#game-left-area .odds-rate.selected {box-shadow: inset 0 0 0 1px #ffe588;background-color:black !important;transition: all .3s ease;}
#game-left-area .game-list-data>div {display:flex;align-items: center;}
#game-left-area .center {justify-content: center;}
#game-left-area .sports_type { margin:2px;width:100px;padding:10px 0px;}

.b-pagination {justify-content:center;margin-top:10px;}

@media (max-width: 575px) {
  #game-left-area {
    padding:0px !important;
    width:100% !important;
    padding-top: 10px !important;
  }
  .app-content {
      padding: 0px !important;
      padding-top: 10px !important;
  }
}

.team-name {
  max-width:55%;
  white-space: nowrap; 
  text-overflow: ellipsis; 
  display:inline-block;
  overflow: hidden;
}

.league-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sub-name {color:aquamarine;}

.ml-05 {margin-left:5px;}
.mr-05 {margin-right:5px;}

@media (max-width: 768px) {
    .horizontal-layout .app-content {
      padding: 10px 0rem 0 0rem !important;
    }
}

.dark-layout .horizontal-layout .header-navbar{ display:none; }
.horizontal-layout.app-content {padding:10px 0px !important;}
.header-navbar-shadow {display:none;}
</style>